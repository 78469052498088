/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

html {
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 14.2px;
  }
}

body
{
    margin: auto;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
}

.logo-badge {
  border-radius: 30%;
  aspect-ratio: 1.0;
  height: 64px;
  width: 64px;
  margin: 2px;
  border: 4px solid var(--official-purple);
  background-color: var(--official-purple);
}

.logo-badge-small {
  border-radius: 30%;
  aspect-ratio: 1.0;
  height: 36px;
  width: 36px;
  margin-right:8px;
  border: 2px solid var(--official-purple);
  background-color: var(--official-purple);
}

.message .mdc-snackbar__surface {
  min-width: 10px !important;
  background-color: white !important;
}

.message .mdc-snackbar__label {
  color: brown !important;
}

/* mat-field select css */
.xapa-select mat-label {
  font-size: 14px;
  font-weight: bold;
}

.xapa-select .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.xapa-select-panel.mat-mdc-select-panel {
  border-radius: 0 !important;
}

.xapa-select .mat-mdc-select-value-text {
  font-size: 14px;
}

.xapa-select .mdc-line-ripple::before,
.xapa-select .mdc-line-ripple::after {
  border: 0 !important;
}

.xapa-select-panel .mat-mdc-option {
  min-height: 30px;
}

/* table sort icon */
.mat-sort-header-arrow {
  display: none !important;
}

.xapa-table-sort svg path {
  fill: #0066ff !important;
}

.xapa-sort-header .mat-sort-header-content,
.xapa-sort-header .mat-sort-header-container {
  display: flex;
  align-items: center;
  justify-content: center !important;
}